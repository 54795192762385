<template>
  <perfect-scrollbar v-if="!isUnderShow" class="p-4 ContentsBox">

    <div class="flex flex-between">
      <span class="bold">ステータス管理</span>
      <span>
        <span class="bold mr-5" v-if="userName">
          申請者氏名： {{userName[0].field_answer_text}}
          <span v-if="userName[1]"> {{userName[1].field_answer_text}}</span>
          </span>
        <span class="bold">申請番号： {{code}}</span>
      </span>
    </div>
    <hr class="mt-1 cotrol-line">

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">申請者メールアドレス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{mailInfo.login_id}}
        <span v-if="isDeletedUser" class="error">
          ユーザーが削除されているため、メール送信はできません。
        </span>
      </b-col>
      <b-col sm="3" v-if="mailInfo.has_invalid_email_address"></b-col>
      <b-col sm="9" v-if="mailInfo.has_invalid_email_address"
        class="flex flexMiddle error">
        システムからこのユーザーへのメールが届かなかったため、このユーザーへのメール送信はブロックされています。<br>
        ユーザーがログインに成功すると、ブロックが解除されます。
      </b-col>
    </b-row>

    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">現在のステータス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{statusTexts[status]}}
      </b-col>
    </b-row>

    <hr>

    <b-row class="">
      <b-col sm="3" class="label">
        <label class="labelText bold">ステータス</label>
      </b-col>
      <b-col sm="4">
        <b-form-select
          class="h-50px"
          @input="$emit('updateNewStatus', $event)"
          :value="newStatus"
          :options="newStatusList">
        </b-form-select>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            !canInputReason || newStatus == status ? 'opacity03' : '',
          ]">
          受領・差戻・棄却理由</label>
      </b-col>
      <b-col sm="9">
        <div v-if="newStatus != status && canInputReason">
          <span>最新：</span>
          <b-form-textarea
            @input="$emit('updateReason', $event)"
            :value="reason"
            :disabled="!canInputReason"
            :rows="4"
            />
          <hr v-if="comments.length">
        </div>
        <div v-for="(comment, comment_no) in comments" :key="`comment_${comment_no}`">
          <span>{{comments.length - comment_no}}回目：登録日 {{comment.reg_date}}</span>
          <b-form-textarea
            disabled
            :value="comment.comment"
            :rows="4"
            />
          <hr v-if="comment_no !== (comments.length-1)">
        </div>
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
  <perfect-scrollbar v-else class="p-4 ContentsBox">
    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">現在のステータス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{statusTexts[status]}}
      </b-col>
    </b-row>

    <hr>

    <b-row class="">
      <b-col sm="3" class="label">
        <label class="labelText bold">ステータス</label>
      </b-col>
      <b-col sm="4">
        <b-form-select
          class="h-50px"
          @input="$emit('updateNewStatus', $event)"
          :value="newStatus"
          :options="newStatusList">
        </b-form-select>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            !canInputReason || newStatus == status ? 'opacity03' : '',
          ]">
          受領・差戻・棄却理由</label>
      </b-col>
      <b-col sm="9">
        <div v-if="newStatus != status && canInputReason">
          <span>最新：</span>
          <b-form-textarea
            @input="$emit('updateReason', $event)"
            :value="reason"
            :disabled="!canInputReason"
            :rows="4"
            />
          <hr v-if="comments.length">
        </div>
        <div v-for="(comment, comment_no) in comments" :key="`comment_${comment_no}`">
          <span>{{comments.length - comment_no}}回目：登録日 {{comment.reg_date}}</span>
          <b-form-textarea
            disabled
            :value="comment.comment"
            :rows="4"
            />
          <hr v-if="comment_no !== (comments.length-1)">
        </div>
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';


export default {
  name: 'cmsInterimRepControl',
  components: {
  },
  props: {
    status: { type: Number, default: null },
    newStatus: {},
    reason: {},
    mailInfo: { type: Object, default: () => {} },
    onGoing: { type: Boolean },
    code: {},
    userName: {},
    isUnderShow: { type: Boolean, default: false },
    comments: {},
  },
  data() {
    return {
      constStatus: CONST_STATUS.APP_STATUS,
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
      newStatusList: CONST_STATUS.CMS_REP_STATUS_OPTION,
    };
  },
  methods: {
  },
  computed: {
    canInputReason() {
      const hasReasonStatusList = CONST_STATUS.HAS_REASON_STATUS;
      return hasReasonStatusList.indexOf(Number(this.newStatus)) !== -1;
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
  }
</style>
