<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">{{base.year}}年度 {{base.name}} - 第{{interimRep.sort}}回進捗状況報告</p>
      <hr class="title">
    </div>

      <div
        class="mb-3 mx-auto contentsWidth">
        <div>
          <v-interim-control
            @updateNewStatus="updateNewStatus"
            @updateReason="updateReason"
            :userName="base.ans_name"
            :code="base.code"
            :status="interimRep.status"
            :newStatus="newStatus"
            :reason="reason"
            :mailInfo="mailInfo"
            :comments="comments"/>
        </div>
        <div class="mt-5">
          <div class="col-12 px-0">
            <div>
              <b-row class="mt-2 mx-4 mb-2">
                <div class="label pl-0 mb-0 pr-1 mr-3">
                  <div class="badgeWrap"></div>
                  <div class="labelWrap mb-0">
                    <label class="formLabel mb-0 bold">申請時 {{usecasesLabel[constUsecases.TITLE]}}</label>
                  </div>
                </div>
                <div class="mainWidth">
                  <div class="my-2 ml-1">
                    {{viewUsecase(usecases[constUsecases.TITLE], 0)}}
                  </div>
                </div>
              </b-row>
            </div>
            <hr>
          </div>

          <div class="col-12 px-0">
            <div>
              <b-row class="mt-2 mx-4 mb-2">
                <div class="label pl-0 mb-0 pr-1 mr-3">
                  <div class="badgeWrap"></div>
                  <div class="labelWrap mb-0">
                    <label class="formLabel mb-0 bold">申請時 {{usecasesLabel[constUsecases.TERM]}}</label>
                  </div>
                </div>
                <div class="mainWidth">
                  <div class="my-2 ml-1">
                    {{viewUsecase(usecases[constUsecases.TERM], 0)}}
                    ~
                    {{viewUsecase(usecases[constUsecases.TERM], 1)}}
                  </div>
                </div>
              </b-row>
            </div>
            <hr>
          </div>
          <div
            v-for="(field, index) in fields" :key="index"
            class="col-12 px-0">
            <v-app-field
              :fieldId='field.field_id'
              :fieldLabel="field.label"
              :fieldType="field.type"
              :description="field.description"
              :notice="field.notice"
              :is_required="field.is_required"
              :type="field.type"
              :label="field.label"
              :is_variable="field.is_variable"
              :input_number="field.input_number"
              :min_input_number="field.min_input_number"
              :max_input_number="field.max_input_number"
              :view_template="field.view_template"
              :options.sync="field.options"
              :rules="field.rules"
              :messages="field.messages"
              :params="field.params"
              :writable="true"
              :downloadFormFile="downloadFormFile"
              :downloadApploadedFile="downloadApploadedFile"
            />
              <!-- :writable="writable"
              :assets="assets"
              :downloadOpenFile="downloadOpenFile" -->
            <hr>
          </div>
        </div>
      </div>

    <!-- <div class="bottonArea mt-4 mb-3 flex flexCenter">
      <p
        v-show="hasEmpty"
        class="warning">
        未入力の必須項目があります。すべての必須項目に入力後、登録を行ってください。
      </p>
    </div> -->
    <div>
      <v-interim-control
        @updateNewStatus="updateNewStatus"
        @updateReason="updateReason"
        :userName="base.ans_name"
        :code="base.code"
        :status="interimRep.status"
        :newStatus="newStatus"
        :reason="reason"
        :mailInfo="mailInfo"
        :isUnderShow="true"
        :comments="comments"/>
    </div>
    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">
        前の報告
      </b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/interimReps/list">
        一覧に戻る
      </b-button>
      <b-button
        @click="save"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">
        登録
      </b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg mr-4 bold">
        次の報告
      </b-button>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
// eslint-disable-next-line
import download from '@/modules/download';
// eslint-disable-next-line
import InterimControl from '@/components/cms/interim/Control.vue';
import Field from '@/components/parts/application/Field.vue';
import USECASE from '@/constants/usecase';

export default {
  name: 'cmsReportEdit',
  components: {
    'v-app-field': Field,
    'v-interim-control': InterimControl,
  },
  data() {
    return {
      interimId: null,
      sort: null,
      // 申請データ
      base: {},
      interimRep: {},
      // 入力欄データ
      fields: {},
      usecases: {},
      usecasesLabel: {},
      constUsecases: USECASE.USECASE,
      mailInfo: {},
      newStatus: null,
      reason: '',
      comments: {},
    };
  },
  computed: {
    viewUsecase() {
      return (usecase, index) => {
        if (!usecase) {
          return '';
        }
        return usecase[index].field_answer_text;
      };
    },
    filterdIdList() {
      return this.$store.state.cmsReportSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.indexOf(Number(this.interimId));
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    init() {
      this.base = {};
      this.fields = {};
      this.usecases = {};
      this.usecasesLabel = {};
      this.interimRep = {};
      this.newStatus = null;
      this.reason = '';
      this.comments = {};
      this.$store.commit('gasApplicationInput/initErrMessages');
    },
    async fetchReptype(repId) {
      this.init();
      const param = {
        repId,
      };
      const response = await api.send('/api/cms/interimRep/edit', param)
        .catch((err) => {
          console.error(err);
        });

      if (!response) {
        await this.alert('申請のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/interimReps/list' });
        return;
      }
      const { data } = response;
      this.base = data.base;
      this.fields = data.fields;
      this.usecases = data.usecases;
      this.usecasesLabel = data.usecasesLabel;
      this.interimRep = data.interimRep;
      this.newStatus = data.interimRep.status;
      this.reason = '';
      this.comments = data.interimRep.comments;
      // formのデータ
      const dbDataNoPage = { fields: data.fields, answers: data.answers };
      this.$store.commit('gasApplicationInput/setDbDataNoPage', dbDataNoPage);
      // ユーザーが削除されていたらからサーバーからnullが来るので空オブジェクトに置き換え
      this.mailInfo = response.data.mailInfo || {};
    },
    updateNewStatus(value) {
      this.newStatus = value;
    },
    updateReason(value) {
      this.reason = value;
    },
    async save() {
      let msg = '上記の内容で報告を登録します。';
      if (this.interimRep.status !== Number(this.newStatus) && !this.mailInfo.has_invalid_email_addressl && !this.isDeletedUser) {
        msg += '\nステータスが変更されているため、報告者にメールの送信と通知の登録が行われます。';
      } else if (this.interimRep.status !== Number(this.newStatus) && !this.isDeletedUser) {
        msg += '\nステータスが変更されてますが、報告者へのメール送信がブロックされているため、通知の登録のみが行われます。';
      }

      msg += '\n登録してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        appId: this.interimRep.application_id,
        sort: this.interimRep.sort,
        interimId: this.interimRep.id,
        userId: this.interimRep.user_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
        newStatus: this.newStatus,
        reason: this.reason,
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/cms/interimRep/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
          } else {
            await this.alert(err.response.data.msg, false);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('データを更新しました。');
      this.$store.dispatch('page/onLoading');
      this.$store.commit('gasApplicationForm/init');
      this.$store.commit('gasApplicationForm/setIsCms', true);
      const { repId } = this.$route.params;
      this.interimId = repId;
      await this.fetchReptype(repId);
      this.$store.dispatch('page/offLoading');
    },
    async nextApp() {
      const newIndex = this.filterdIdListIndex + 1;
      this.$router.push({ path: `/cms/interimReps/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.interimId = this.filterdIdList[newIndex];
      await this.fetchReptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdListIndex - 1;
      this.$router.push({ path: `/cms/interimReps/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.interimId = this.filterdIdList[newIndex];
      await this.fetchReptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    setErrors(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        user_id: this.interimRep.user_id,
        interim_rep_id: this.interimId,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/cms/interimRep/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/cms/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/cms/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('gasApplicationForm/setIsCms', true);
    const { repId } = this.$route.params;
    this.interimId = repId;
    await this.fetchReptype(repId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }

  .err-wrap>p{
    color: #dc3545;
  }

  .label {
    width: 260px;
    max-width: 260px;
  }
</style>
